import React from "react";
import Cards from "../../Cards/Cards";
import Marcas from "../../Marcas/Marcas";
import Title from "../../Title";
import style from "./Home.module.css";
import Produtos from "../../Produtos/Produtos";
import ProdutoDestaque from "../../Produtos/ProdutoDestaque";
import banner from "../../Assets/frete.png";
import { useEffect, useState, useContext } from "react";
import refimg from '../../Assets/refimg.png'
import LinksMenuMobileComponet from "../../Header/LinksMenuMobileComponet";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
  return (<>

    <div className={style.home}>
      {/* <br /> */}
      <LinksMenuMobileComponet />
      <div className={style.borders}>
        <p className={style.p}>🖤 🐶Чёрная пятница!
        </p>
        <p className={style.p}>Скидка 10% на Ваши любимые продукты от KOTOPES 🖤
        </p>
        <p className={style.p}>🖤Акция действует с 9.11 по 15.11
        </p>
        <p className={style.p}>Промокод: <b>BF2024</b>
        </p>
        </div>
      <div className={style.borders}>

        <img className={style.p} src={refimg}>
        </img>

      </div>
      <br />
      <div className={style.borders}>
        <p className={style.p}>Питание домашних питомцев – это первый и основной вопрос, возникающий перед их владельцами, потому что это основа жизнедеятельности, здоровья и активности ваших животных. Обычно новые владельцы спрашивают, чем кормить собаку или кошку, у заводчика или у продавца. Многие люди уверены, что животному подходит то же питание, что и для них самих. Наиболее ответственные владельцы покупают специализированную литературу или консультируются у специалистов (ветеринаров, кинологов, фелинологов).
        </p>
        <br></br>
        <p className={style.p}>Наша компания взяла на себя решение этого вопроса и подготовила для владельцев кошек и собак линейки влажных (консервированных) кормов. А также мы предлагаем вам сушеные лакомства (вкусняшки) и свежемороженую натуральную продукцию.
          Выбор готового натурального и качественного корма позволит вам сэкономить силы и деньги, а также подарит вам и вашему питомцу драгоценное время, проведенное за веселыми играми.
        </p>
        <br></br>
        <p className={style.p}>​Для сбалансированного и полноценного питания собакам и кошкам нужны белки, жиры и углеводы, и их правильное соотношение в зависимости от возраста животного и наличия у них заболеваний (мочеполовой системы, желудочно-кишечного тракта, ожирения, аллергических реакций (заболеваний кожи и шерсти).</p>
        <br></br>
        <p className={style.p}>Наши корма сбалансированы и произведены согласно рекомендаций ветеринаров под чутким их наблюдением.
          Выбор готового натурального и качественного корма позволит вам сэкономить силы и деньги, а также подарит вам и вашему питомцу драгоценное время, проведенное за веселыми играми.
        </p>
      </div>
      <br />
      <br />

      <Title text="НЕМНОГО О НАС" />
      <div className={style.borders}>

        <p className={style.p} >Мы - единственное в Калининградской области сертифицированное местное производство консервов для домашних животных.
        </p>

      </div>
      <br></br>
      <div className={style.borders}>

        <p className={style.p}>
          Наша продукция прошла лабораторные исследования, имеет ветеринарный  сертификат и декларацию соответствия.
        </p>


      </div>
      <br></br>
      <div className={style.borders}>

        <p className={style.p} >Наши поставщики — сертифицированные бойни, работающие с фермерскими хозяйствами Калининградской области, где животные имеют ветеринарные свидетельства.
        </p>

      </div>
      <br></br>
      <div className={style.borders}>

        <p className={style.p}
        //  style={{ color: 'var(--primary-color)' }}
        >

          Мы сами выбираем мясо и поставщиков и получаем мясо от фермеров в течение нескольких часов после забоя в охлаждённом виде, поэтому выбранное мясо — свежее, а продукция высокого качества и безопасная.      </p>


      </div>
      <br></br>
      <div className={style.borders}>

        <p className={style.p}>Наша продукция производится на основе только натурального сырья, мы постоянно совершенствуем собственный рецепт, чтобы вкус и запах получались изумительными и не могли оставить равнодушными домашних питомцев</p>

      </div>
      <br></br>
      <div className={style.borders}>

        <p className={style.p}>

          Наша продукция, не содержит консерванты, усилители вкуса и химические добавки. Единственный консервант — правильная температура и время.
        </p>


      </div>
      <br />
    </div>
    {/* <div>
      <Title text="Хит продаж!" />
      <br />
      <div className={style.container_destaques}>
        <div>
          <ProdutoDestaque />
        </div>

        <div className={style.container_produtos}>
          <Produtos amount={3} />
        </div>
      </div>
      <div style={{ margin: "20px auto", width: "75vw" }}>
        <img style={{ width: "100%", marginTop: "60px" }} src={banner} />
      </div>
    </div > */}
  </>
  );
};

export default Home;
